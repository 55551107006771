/*
 * @Author: your name
 * @Date: 2021-03-15 17:24:48
 * @LastEditTime: 2021-07-26 15:14:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hanyun-iot\src\mock\data\dict.js
 */
// const netStyle = [
//     {value:'1',label:'蜂窝（2G/3G/4G/5G）'},
//     {value:'2',label:'以太网'},
//     {value:'3',label:'WIFI'},
//     {value:'4',label:'其他'}
// ]

// const Protocol = [
//     {value:'1',label:'MQTT'},
//     {value:'2',label:'HTTP'},
//     {value:'3',label:'COAP'},
//     {value:'4',label:'XM2M'},
//     {value:'5',label:'JT808'},
//     {value:'6',label:'GB32960'}
// ]
// const comfirmStyle = [
//     {value:'1',label:'用户名密码（一型一密）'},
//     {value:'2',label:'用户名密码（一机一密）'},
//     {value:'3',label:'X.509证书'},
// ]

// const dataSTyle = [
//     {value:'1',label:'自定义'},
//     {value:'2',label:'JSON'},
// ]

// export default { netStyle,Protocol,comfirmStyle,dataSTyle }

export const getDict = params => {
  const dict = params.dict;
  if (dict && DICT[dict]) {
    return DICT[dict];
  }
  return {};
};

export default [
  {
    url: RegExp("/api-ai/api/v1/dict/getDict" + ".*"),
    method: "get",
    result: { msg: "获取字典成功" },
    mock: options => {
      const uri = options.url.split("?")[1];
      let params = {};
      uri.split("&").forEach(s => {
        const kv = s.split("=");
        params[kv[0]] = kv[1];
      });
      return getDict(params);
    }
  }
];

