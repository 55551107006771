<!--
 * @Author: your name
 * @Date: 2021-07-22 11:05:55
 * @LastEditTime: 2021-08-06 17:48:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hanyun-iot\src\views\manager\document-source-manager.vue
-->

<template>
  <div class="document-source-manager">
    <els-bread class="_bread" ></els-bread>
    <div class="_header d-header">
      <els-button-group
        type="primary"
        size="small"
        :column="buttons"
      ></els-button-group>
      <div class="h--search">
        <el-input
          v-model="searchValue"
          prefix-icon="el-icon-search"
          :placeholder="lang.documentCode"
          size="small"
        ></el-input>
      </div>
    </div>
    <div class="_content d-content">
      <els-table
        ref="dRefs"
        :column="column"
        :onload="onload"
        menuWidth="180px"
      >
        <template v-slot:menu="{ row }">
          <el-button
            v-if="row.status === '0'"
            type="text"
            @click.stop="handleUpdateStatus(row, '1')"
            >{{lang.disable}}
          </el-button>
          <el-button
            v-if="row.status === '1'"
            type="text"
            @click.stop="handleUpdateStatus(row, '0')"
            >{{lang.enable}}</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click.stop="handEdit(row)">{{lang.edit}}</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click.stop="handDel(row)">{{lang.delete}}</el-button>
        </template>
      </els-table>
    </div>
    <els-form-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      :loading="loadingBtn" :cancel="lang.cancel" :submit="lang.confirm"
      @submit="handleSubmit"
    >
      <els-form
        v-if="dialogVisible"
        ref="elsFormRef"
        v-model="formData"
        :column="formColumn"
        @change-path="changePath"
      ></els-form>
    </els-form-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixinsDicts from "@/mixins/dict.js";
import elsBread from "@/components/els-breadcrumb/main.vue";
import elsTable from "@/components/els-table/main.vue";
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import { getDict } from "@/mock/data/dict.js";
import mixinsI18n from "@/mixins/i18n.js"
import {
  getDocumentList,
  deleteDocumentSource,
  updateStatus,
  queryDocumentDesc,
  fileAdd,
  fileModify,
} from "@/api/manager/document-source-manager.js";
export default {
  name: "document-source-manager",
  mixins: [mixinsDicts, mixinsI18n],
  components: { elsBread, elsTable, elsFormDialog, elsForm },
  data: () => {
    return {
      PAGE_NAME: "documentSourceManagePage",
      columnBread: [],
      dicts: {
        DOCUMENT_TYPE: [],
        DOCUMENT_STATUS: [],
      },
      searchValue: "",
      dialogVisible: false,
      dialogTitle: "",
      formData: {},
      fileList: [],
      loadingBtn: false,
    };
  },
  computed: {
    ...mapState(["breadMenus"]),
    buttons() {
      const addFiles = { label: this.lang.addFile, handle: this.handleAdd };
      return [addFiles];
    },
    column() {
      const type = {
        prop: "type",
        label: this.lang.fileType,
        dict: this.dicts.DOCUMENT_TYPE,
      };
      const code = { prop: "code", label: this.lang.fileCode };
      const remark = { prop: "remark", label: this.lang.fileDes };
      const version = { prop: "version", label: this.lang.version };
      const size = { prop: "size", label: this.lang.fileSize };
      const updateTime = {
        prop: "updateTime",
        label: this.lang.lastModTime,
         minWidth: 140,
      };
      const status = {
        prop: "status",
        label: this.lang.state,
        dict: this.dicts.DOCUMENT_STATUS,
      };
      return [type, code, remark, version, size, updateTime, status];
    },
    formColumn() {
      const type = {
        tag: "el-select",
        prop: "type",
        label: this.lang.fileType,
        placeholder: this.lang.pleaseSelect,
        required: true,
        cls: this.dicts.DOCUMENT_TYPE,
        trigger: "change",
      };
      const code = {
        prop: "code",
        label: this.lang.fileCode,
        required: true,
        rules: [
          {
            pattern: /^[0-9a-zA-Z_-]*$/,
            message: this.lang.inputLetDasUnder,
          },
          { max: 50, message: this.lang.max50, trigger: "blur" },
        ],
      };
      const version = {
        prop: "version",
        label: this.lang.fileVersion,
        required: true,
        rules: [
          {
            pattern: /^\w+((\.|\-)\d+)*$/,
            message: this.lang.fileVersionRule,
          },
          { max: 50, message: this.lang.max50, trigger: "blur" },
        ],
      };
      const remark = {
        prop: "remark",
        label: this.lang.fileDes,
        type: "textarea",
        rules: [{ max: 50, message: this.lang.max50, trigger: "blur" }],
      };
      const path = {
        tag: "self-upload",
        label: this.lang.fileUpload,
        prop: "path",
        size: "mini",
        accept: "",
        limitSize: 200,
        beforeUpload: this.beforeUpload,
        btnText: this.lang.uploadFile,
        rules: [
          {
            required: true,
            message: this.lang.fileUpError,
            trigger: "blur",
          },
        ],
        fileList: this.fileList,
      };
      const size = {
        prop: "size",
        label: this.lang.fileSize,
        disabled: true,
        required: true,
        value: 0,
      };
      return [type, code, version, remark, path, size];
    },
  },
  watch: {
    searchValue(v) {
      this.reloadTable();
    },
    dialogVisible(v) {
      if (!v) {
        this.formData = {};
        this.loadingBtn = false;
      }
    },
  },
  methods: {
    async onload(page, query) {
      try {
        const p = {
          pageNum: page.pageNum,
          pageSize: page.pageSize,
          code: this.searchValue,
        };
        const res = await getDocumentList(p);
        if (res.result.data) {
          const total = res.result.total || res.result.data.length;
          return {
            list: res.result.data.map((item) => ({
              ...item,
              id: item.id,
              size:
                item.size / 1024 / 1024 > 1
                  ? (item.size / 1024 / 1024).toFixed(2) + "M"
                  : item.size / 1024 > 1
                  ? (item.size / 1024).toFixed(2) + "K"
                  : item.size + "B",
            })),
            total,
          };
        }
      } catch (err) {
        console.log(err);
        return { list: [], total: 0 };
      }
    },
    beforeUpload(file) {
      if (file.size === 0) {
        this.$message.warning(this.lang.fileNotNull);
        return false;
      }
      const size = file.size;
      // const size =
      //   file.size / 1024 / 1024 > 1
      //     ? (file.size / 1024 / 1024).toFixed(2) + "M"
      //     : file.size / 1024 > 1
      //     ? (file.size / 1024).toFixed(2) + "k"
      //     : file.size + "B";
      if (file.size / 1024 / 1024 > 200) {
        this.$message.warning(this.lang.fileSizeEx);
        return false;
      }
      const name = file.name;
      this.formData.size = size;
      this.formData.originName = name;
    },
    initDicts() {
      const dict = Object.keys(this.dicts);
      dict.forEach((k) => {
        const res = getDict(k);
        if (res) {
          this.dicts[k] =
            res && res.length > 0
              ? res.map((item) => ({
                  label: item.name,
                  value: item.value,
                }))
              : [];
        }
      });
    },
    async handleUpdateStatus(params, status) {
      const { id } = params;
      try {
        await updateStatus(id, status);
        this.reloadTable();
      } catch (err) {
        console.log(err);
      }
    },
    handleAdd() {
      this.dialogVisible = true;
      this.dialogTitle = this.lang.addFile;
      this.fileList = [];
    },
    async handEdit(params) {
      try {
        const res = await queryDocumentDesc(params.id);
        const {
          id,
          type,
          code,
          remark,
          size,
          status,
          path,
          originName,
          version,
        } = res.result;
        this.formData = {
          id,
          type,
          code,
          remark,
          size,
          status,
          path,
          originName,
          version,
        };
        this.fileList = [{ name: res.result.originName, url: res.result.path }];
        this.dialogVisible = true;
        this.dialogTitle = this.lang.editFile;
      } catch (err) {
        console.log(err);
      }
    },
    handDel(d) {
      this.$confirm(this.lang.delMsg, this.lang.tips,{
        confirmButtonText: this.lang.confirm,
        cancelButtonText: this.lang.cancel,
        type: "warning",
      })
        .then(() => {
          this.deleteRule({ id: d.id });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleteRule(id) {
      try {
        const res = await deleteDocumentSource(id);
        this.$message.success(res.msg);
        this.reloadTable();
      } catch (e) {
        console.log(e);
      }
    },
    async handleSubmit() {
      this.loadingBtn = true;
      try {
        await this.$refs["elsFormRef"].validate();
        if (this.formData.id) {
          this.editFile({ ...this.formData });
        } else {
          this.addfile({ ...this.formData, status: 0 });
        }
      } catch (err) {
        this.loadingBtn = false;
        console.log(err);
      }
    },
    async addfile(form) {
      try {
        const res = await fileAdd(form);
        this.$message({ type: "success", message: res.msg });
        this.dialogVisible = false;
        this.reloadTable();
        this.loadingBtn = false;
      } catch (err) {
        this.loadingBtn = false;
        console.log(err);
      }
    },
    async editFile(form) {
      try {
        const res = await fileModify(form);
        this.$message({ type: "success", message: res.msg });
        this.dialogVisible = false;
        this.reloadTable();
        this.loadingBtn = false;
      } catch (err) {
        this.loadingBtn = false;
        console.log(err);
      }
    },
    changePath(v) {
      if (!v) {
        this.formData.size = 0;
      }
      if (v) {
        this.$refs["elsFormRef"].validateField("path");
      }
    },
    reloadTable() {
      this.$refs.dRefs && this.$refs.dRefs.apiOnload();
    },
  },
  mounted() {
    this.columnBread = this.breadMenus;
    // this.initDicts();
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-button--text:nth-child(5) {
  color: red;
}
::v-deep .el-select {
  width: 100%;
}
.document-source-manager {
  width: 100%;
  height: 100%;
  .d-header {
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .d-content {
    padding: 24px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: calc(100% - 44px - 54px);
  }
}
</style>

