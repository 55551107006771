/*
 * @Author: your name
 * @Date: 2021-07-22 14:04:04
 * @LastEditTime: 2021-07-23 14:15:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hanyun-iot\src\api\manager\document-source-manager.js
 */
import http from "@/axios/index"
import { URI } from "@/config/axios"

export const getDocumentList = (params = {}) => {
    return http({
        url: URI.system + `api/attachFile/queryPageList`,
        method: 'get',
        params: {
            ...params
        }
    })
}
// 删除
export const deleteDocumentSource = (id) => {
    return http({
        url: URI.system + 'api/attachFile/delete',
        method: 'delete',
        params: id
    })
}
// 更改资源状态
export const updateStatus = (id, status) => {
    return http({
        url: URI.system + 'api/attachFile/updateStatus',
        method: 'post',
        params: { id: id, status: status }
    })
}
// 详情
export const queryDocumentDesc = (id) => {
    return http({
        url: URI.system + 'api/attachFile/queryById',
        method: 'get',
        params: { id: id }
    })
}
// add
export const fileAdd = (data) => {
    return http({
        url: URI.system + 'api/attachFile/add',
        method: 'post',
        data: { ...data }
    })
}

// Edit
export const fileModify = (data) => {
    return http({
        url: URI.system + 'api/attachFile/modify',
        method: 'put',
        data: { ...data }
    })
}